<template>
	<div class="container">
		<div class="mask">
			<div class="mask-info">
				<div class="title">
					<span>磨机衬板磨损点修改</span>
				</div>
				<div class="test">
					<img src="../../static/images/success.png" alt="">
					<p>您的磨损点云数据修改成功</p>
				</div>
				<div class="bottom-btn">
					<button @click="close">好的</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			isShow:{
				type:Boolean,
				default:false
			},
		},
		data() {
			return {
				
			}
		},
		created() {
			
		},
		methods: {
			close(){
				this.$emit('close','');
			}
		}
	}
</script>

<style lang="scss">
	.mask{
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba($color: #000000, $alpha: .3);
		display: flex;
		justify-content: center;
		align-items: center;
		&-info{
			background-color: #fff;
			border-radius: 5px;
			width: 450px;
			position: relative;
			overflow: hidden;
			min-height: 300px;
			@media screen and (min-width:230px) and (max-width:767px) {
				width: 80%;
				min-height: 250px;
			}
			.title{
				background-color: #fff;
				text-align: center;
				font-weight: 800;
				font-size: 18px;
				padding: 15px 0;
				border-bottom: 1px solid #e5e5e5;
			}
			.test{
				font-size: 14px;
				padding: 40px;
				line-height: 20px;
				text-align: center;
				img{
					display: block;
					width: 40%;
					margin: 0 auto;
					margin-bottom: 30px;
				}
			}
			.bottom-btn{
				margin-bottom: 20px;
				text-align: center;
				button{
					border: 0;
					cursor: pointer;
					width: 50%;
					height: 40px;
					background-color: #fff;
					&:last-child{
						background: linear-gradient(-36deg, #0064C8, #3995F0);
						color: #fff;
					}
				}
			}
		}
	}
</style>
