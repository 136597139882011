<template>
	<div class="container">
		<top-header></top-header>
		<div class="databaseUpload width-1300">
			<div class="title">
				<span>修改衬板磨损点数据</span>
			</div>
			<div class="upload-from">
				<div class="upload-from-item">
					<div class="label">所属矿山</div>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="upload-from-item">
					<div class="label">地图定位</div>
					<div class="location">
						<p class="btn">选择地址</p>
					</div>
				</div>
				<div class="upload-from-item">
					<div class="label">所属磨机</div>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="upload-from-item">
					<div class="label">磨机类型</div>
					<p>123</p>
				</div>
				<div class="upload-from-item">
					<div class="label">磨机直径</div>
					<p>123</p>
				</div>
				<div class="upload-from-item">
					<div class="label">磨机长度</div>
					<p>123</p>
				</div>
				<div class="upload-from-item">
					<div class="label">衬板安装类型</div>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="upload-from-item">
					<div class="label">衬板安装时间</div>
					<input type="text">
				</div>
				<div class="upload-from-item">
					<div class="label">扫描时间</div>
					<input type="text">
				</div>
				<div class="upload-from-item">
					<div class="label">衬板附件</div>
					<el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
						:on-change="encFileEdit" ref="myload" :auto-upload="false">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</div>
				<div class="upload-from-item">
					<div class="label">衬板状态图</div>
					<el-upload action="#" list-type="picture-card" ref="imageload" :auto-upload="false"
						:on-change="imageFileEdit">
						<i slot="default" class="el-icon-plus"></i>
						<div slot="file" slot-scope="{file}">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</div>
				<div class="upload-from-item">
					<el-button type="primary" class="from-submit">上传数据</el-button>
				</div>
			</div>
			<div class="upload-from-item">
				<el-button type="primary" class="model-from-submit" @click="submit">上传数据</el-button>
			</div>
		</div>
		<bottom-footer></bottom-footer>
		<update-mask v-if="isShow" @close="close"></update-mask>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	import updateMask from '../../components/updatemask.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
			updateMask
		},
		data() {
			return {
				isShow: false,
				radio: 3,
				dialogImageUrl: '',
				dialogVisible: false,
				disabled: false,
				options: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}, {
					value: '选项3',
					label: '蚵仔煎'
				}, {
					value: '选项4',
					label: '龙须面'
				}, {
					value: '选项5',
					label: '北京烤鸭'
				}],
				value: ''
			}
		},
		created() {

		},
		methods: {
			close() {
				this.isShow = false;
			},
			submit() {
				this.isShow = true;
			},
			handleRemove(file) {
				//console.log(file);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			imageFileEdit(file, fileList) {
				if (fileList.length > 1) { //这里只获取最后一次选择的问津
					fileList.splice(0, 1);
				}
				if (fileList.length > 0) {
					let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.') + 1);
					let extension1 = fileName === 'png';
					let extension2 = fileName === 'jpg';
					let extension3 = fileName === 'jpeg';
					if (!extension1 && !extension2 && !extension3) {
						this.$refs.imageload.clearFiles();
						this.$notify({
							type: 'error',
							title: '提示',
							message: '请上传正确格式的文件，例如：index.html',
						});
						return false;
					} else {
						this.$refs.imageload.submit();
					}
				}
			},
			encFileEdit(file, fileList) {
				if (fileList.length > 1) { //这里只获取最后一次选择的问津
					fileList.splice(0, 1);
				}
				if (fileList.length > 0) {
					let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.') + 1);
					let extension1 = fileName === 'html';
					if (!extension1) {
						this.$refs.myload.clearFiles();
						this.$notify({
							type: 'error',
							title: '提示',
							message: '请上传正确格式的文件，例如：index.html',
						});
						return false;
					} else {
						this.$refs.myload.submit();
					}
				}
			},
		}
	}
</script>

<style lang="scss">
	.el-button{
		background: linear-gradient(-36deg, #0064C8, #3995F0);
	}
	.databaseUpload {
		margin-top: 145px;
		min-height: 773px;

		@media screen and(min-width:320px) and(max-width:767px) {
			margin-top: 0;
			padding: 0 0;
		}

		.title {
			text-align: center;
			color: #fff;

			@media screen and(min-width:320px) and(max-width:767px) {
				background-color: #107FEF;
				padding: 100px 30px;
				box-sizing: border-box;
				text-align: left;
			}
		}

		.upload-from {
			padding: 40px;
			border-radius: 10px;
			box-sizing: border-box;
			margin-top: 40px;
			box-shadow: 0px 7px 21px 0px rgb(0 0 0 / 15%);

			@media screen and(min-width:320px) and(max-width:767px) {
				margin: -60px 20px 20px;
				background-color: #fff;
				padding: 20px 30px 0;
			}

			&-item {
				text-align: center;
				margin-bottom: 20px;

				@media screen and(min-width:320px) and(max-width:767px) {
					border-bottom: 1px solid #b9b9b9;
					padding-bottom: 5px;

					&:nth-last-child(1),
					&:nth-last-child(2) {
						border: 0;
					}
				}
				
				&:nth-last-child(2) {
					.label {
						padding-top: 15px;
					}

					@media screen and(min-width:320px) and(max-width:767px) {
						.label {
							padding-top: 0px;
						}
					}
				}

				div:nth-child(2) {
					display: inline-block;
					text-align: left;
					width: 250px;

					@media screen and(min-width:320px) and(max-width:767px) {
						width: 100%;
						box-sizing: border-box;
					}
				}

				.el-dialog {
					@media screen and(min-width:320px) and(max-width:767px) {
						width: 90%;
					}
				}

				.el-radio-group {
					width: 250px;
					padding-bottom: 5px;
				}
				p{
					display: inline-block;
					width: 250px;
					text-align: left;
				}
				.from-submit {
					margin-top: 30px;
					width: 150px;
				
					@media screen and(min-width:320px) and(max-width:767px) {
						display: none;
					}
				}

				.model-from-submit {
					display: none;
					margin: 0 auto;
					margin-top: 30px;
					width: 150px;

					@media screen and(min-width:320px) and(max-width:767px) {
						display: block;
					}
				}

				.el-radio {
					overflow: hidden;
				}

				.el-upload--picture-card {
					text-align: center !important;
					width: 100px !important;
					height: 100px;
					line-height: 100px;
				}

				.el-upload-list--picture-card .el-upload-list__item {
					width: 48.2% !important;
					height: auto;

					&:nth-child(2n) {
						margin-right: 0;
					}
				}

				.upload-demo {
					display: inline-block;
					width: 250px;
					vertical-align: middle;
					text-align: left;
				}

				.label {
					text-align: right;
					display: inline-block;
					width: 200px;
					font-size: 14px;
					box-sizing: border-box;
					padding-right: 50px;

					@media screen and(min-width:320px) and(max-width:767px) {
						text-align: left;
						padding: 0 0;
						width: 100%;
						margin-bottom: 10px;
					}
				}

				.location {
					display: inline-block;
					text-align: left;
					width: 250px;
					height: 30px;
					font-size: 14px;

					.btn {
						color: #3292F3;
					}
				}

				input {
					width: 250px;
					height: 40px;
					border-radius: 5px;
					border: 1px solid #b9b9b9;
					outline: none;

					@media screen and(min-width:320px) and(max-width:767px) {
						border: 0;
						width: 100%;
					}
				}
			}
		}
	}
</style>
